import React from 'react'
import Layout from 'components/layout/Layout'
import Banner from 'components/banner/Banner'

const IndexPage: React.FunctionComponent = () => (
  <Layout>
    <Banner />
  </Layout>
)

export default IndexPage
