import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'

interface ImgFile {
  src: string
}

interface Props {
  imgdata: Data
}

interface Data {
  horizontal: {
    childImageSharp: {desktop: ImgFile; desktop2x: ImgFile}
  }
  vertical: {
    childImageSharp: {
      mobile: ImgFile
      mobile2x: ImgFile
      tablet: ImgFile
      tablet2x: ImgFile
    }
  }
}

const Image = styled.div<Props>`
  background-image: url(${(props) => props.imgdata.horizontal.childImageSharp.desktop.src});
  background-position: center 0;
  background-size: cover;
  display: block;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100vw;

  @media (max-width: 480px) {
    background-image: url(${(props) => props.imgdata.vertical.childImageSharp.mobile.src});
  }

  @media (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(${(props) => props.imgdata.vertical.childImageSharp.mobile2x.src});
  }

  @media (min-width: 481px) and (max-width: 768px) {
    background-image: url(${(props) => props.imgdata.vertical.childImageSharp.tablet.src});
  }

  @media (min-width: 481px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(${(props) => props.imgdata.vertical.childImageSharp.tablet2x.src});
  }

  @media (min-width: 769px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1201px) {
    background-image: url(${(props) => props.imgdata.horizontal.childImageSharp.desktop2x.src});
  }
`

const query = graphql`
  query {
    vertical: file(relativePath: {eq: "banner-vertical.jpg"}) {
      childImageSharp {
        mobile: fixed(width: 480, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        mobile2x: fixed(width: 960, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        tablet: fixed(width: 768, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        tablet2x: fixed(width: 1536, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    horizontal: file(relativePath: {eq: "banner-horizontal.jpg"}) {
      childImageSharp {
        desktop: fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
        desktop2x: fixed(width: 2400, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

export default () => <StaticQuery query={query} render={(data: Data) => <Image imgdata={data} />} />
