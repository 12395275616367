import React from 'react'
import styled from 'styled-components'

import Logo from '../header/Logo'
import BannerImage from './BannerImage'

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5em 0 0;
  position: absolute;
  top: 0;
  width: 100vw;

  ${Logo} {
    width: 474px;
  }

  @media (max-width: 768px) {
    padding-top: 4em;

    ${Logo} {
      width: 200px;
    }
  }
`

export default () => (
  <>
    <BannerImage />
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
  </>
)
